<template>
    <div class="ContentZone ContentZone__menu">
        <header :class="['DaciaMainHeader', {'is-menuOpened' : isMobileOpened}]">
            <h1 class="u-hidden-wording">{{welcomeText}}</h1>
            <div v-if="topNavigation" class="header-ngvjus eecaeth11">
                <div class="header-db6xb7 eecaeth1">
                    <a v-for="(topMenuItem, topMenuIndex) in topNavigation" :key="topMenuIndex" :href="topMenuItem.url" class="header-1wjvsqq eecaeth3">{{ topMenuItem.title }}</a>
                </div>
            </div>
            <div class="DaciaMainHeader__mobile">
                <a :href="basicInformation.site_url" title="" class="Link" rel="noopener" data-track="click" data-track-button-text="" :data-track-destination="basicInformation.site_url" data-track-location-in-page="primary-nav" data-track-event="navigationClick">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 20" class="DaciaMainHeader__logo">
                        <path d="M127.109 19.62h7.678V0h-7.678v19.62zM88.459 1.06L80.62 9.01c-.28.302-.431.54-.431.8 0 .259.15.496.431.799l7.838 7.952c.82.821 1.382 1.059 2.59 1.059h25.823v-6.634H93.036L89.668 9.81l3.368-3.176h23.836V0H91.05c-1.21 0-1.77.238-2.591 1.059zM28.413 18.562l7.838-7.952c.28-.303.431-.54.431-.8 0-.259-.15-.497-.431-.8l-7.838-7.95C27.593.238 27.031 0 25.823 0H0v6.634h23.836l3.368 3.176-3.368 3.177H0v6.634h25.822c1.21 0 1.77-.238 2.591-1.059zm141.605 1.059H180L161.173.448c-.3-.299-.536-.448-.794-.448-.257 0-.494.15-.794.448L140.758 19.62h9.897l9.66-9.939 9.703 9.939zm-102.37 0h9.982L58.804.448c-.3-.299-.537-.448-.795-.448-.257 0-.493.15-.794.448L38.39 19.62h9.896l9.66-9.939 9.703 9.939z"></path>
                    </svg>
                </a>
                <div class="DaciaMainHeader__title">
                    <button @click="toggleMobile" class="DaciaMainHeader__iconButton" style="margin-right: 10px">
                        <svg viewBox="0 0 30 30" class="SvgIcon DaciaMainHeader__icon">
                            <path d="M3.781 6H26.22a.781.781 0 010 1.563H3.78a.781.781 0 010-1.563zM3.781 14H26.22a.781.781 0 010 1.563H3.78a.781.781 0 110-1.563zM3.781 22H26.22a.781.781 0 010 1.563H3.78a.781.781 0 110-1.563z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="DaciaMainHeader__desktop">
                <button @click="toggleMobile" class="DaciaMainHeader__overlay" tabindex="0">
                    <svg viewBox="0 0 30 30" class="SvgIcon DaciaMainHeader__close">
                        <path d="M29.999 2.512L27.5.012 14.999 12.504 2.498.012l-2.497 2.5L12.5 15.001.001 27.488l2.497 2.5 12.501-12.49L27.5 29.988l2.497-2.5-12.499-12.487z"></path>
                    </svg>
                    <span class="u-hidden-wording">close</span>
                </button>
                <div class="DaciaMainHeader__content">
                    <div class="DaciaMainHeader__top">
                        <span class="DaciaMainHeader__currentWording">{{gledate}}</span>
                        <span class="DaciaMainHeader__currentSite">{{stranica}}</span>
                    </div>
                    <div :class="['DaciaMainHeader__bottom', {'mobile-header' : mobileHeader}]">
                        <a :href="basicInformation.site_url" title="" class="DaciaMainHeader__brand" rel="noopener" data-track="click" data-track-button-text="" data-track-destination="https://www.dacia.hr" data-track-location-in-page="primary-nav" data-track-event="navigationClick" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 20" class="DaciaMainHeader__logo">
                                <path d="M127.109 19.62h7.678V0h-7.678v19.62zM88.459 1.06L80.62 9.01c-.28.302-.431.54-.431.8 0 .259.15.496.431.799l7.838 7.952c.82.821 1.382 1.059 2.59 1.059h25.823v-6.634H93.036L89.668 9.81l3.368-3.176h23.836V0H91.05c-1.21 0-1.77.238-2.591 1.059zM28.413 18.562l7.838-7.952c.28-.303.431-.54.431-.8 0-.259-.15-.497-.431-.8l-7.838-7.95C27.593.238 27.031 0 25.823 0H0v6.634h23.836l3.368 3.176-3.368 3.177H0v6.634h25.822c1.21 0 1.77-.238 2.591-1.059zm141.605 1.059H180L161.173.448c-.3-.299-.536-.448-.794-.448-.257 0-.494.15-.794.448L140.758 19.62h9.897l9.66-9.939 9.703 9.939zm-102.37 0h9.982L58.804.448c-.3-.299-.537-.448-.795-.448-.257 0-.493.15-.794.448L38.39 19.62h9.896l9.66-9.939 9.703 9.939z"></path>
                            </svg>
                        </a>
                        <div :class="['MainMenu', {'is-visible' : !topNavMenuMobileActive}]">
                            <ul v-if="mainNavigation" class="MainMenu__navList">
                                <li class="MainMenuEntry MainMenu__homeEntry">
                                    <a title=" " class="MainMenuEntry__link" data-track="click" data-track-button-text=" " data-track-destination="/" data-track-location-in-page="primary-nav" data-track-event="navigationClick" :href="basicInformation.site_url">
                                        <span class="MainMenuEntry__linkText"></span>
                                    </a>
                                </li>
                                <li v-for="(main, mainIndex) in mainNavigation" :key="mainIndex" :class="['MainMenuEntry', {'is-expanded' : isActiveDropdown(mainIndex)}]">
                                    <button v-if="!main.meta.mainMenuLink" :class="['MainMenuEntry__link', {'is-opened' : isActiveDropdown(mainIndex)}]" @click="toggleDropdown(mainIndex)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.343 185.343" class="SvgIcon MainMenuEntry__dropicon">
                                            <path d="M51.707 185.343a10.692 10.692 0 01-7.593-3.149 10.724 10.724 0 010-15.175l74.352-74.347L44.114 18.32c-4.194-4.194-4.194-10.987 0-15.175 4.194-4.194 10.987-4.194 15.18 0l81.934 81.934c4.194 4.194 4.194 10.987 0 15.175l-81.934 81.939a10.678 10.678 0 01-7.587 3.15z" ></path>
                                        </svg>{{main.title}}
                                    </button>
                                    <div v-if="main.meta.vehicle_menu" :class="['EditorialContentZone', 'MainMenuEntry__megaDropdown', {'MainMenuEntry__megaDropdown_isHidden' : !isActiveDropdown(mainIndex)}]">
                                        <div class="MainMenuRangePicker MainMenuRangePicker_vertical">
                                            
                                            <button :style="vehicleNavIndex == 0 ? 'order:' + vehicleNavIndex : 'order:' + (vehicleNavIndex*2)" @click="toggleVehicleGroup(vehicleNavIndex)" v-for="(vehicleNav, vehicleNavIndex) in main.children[0].children" :key="vehicleNavIndex" :class="['MainMenuRangePicker__tab', 'MainMenuRangePicker__tab_pos0', {'is-lastActive' : isActive(vehicleNavIndex)}, {'is-active' : isActive(vehicleNavIndex)}]" data-track="click" data-track-event="toggle" data-track-toggle-type="tab-selector" :data-track-toggle-value="vehicleNav.title">
                                                <p class="MainMenuRangePicker__tabTitle MainMenuMegaDropDownContent__tab">{{vehicleNav.title}}</p>
                                            </button>
                                            <div :style="vehiclesIndex == 0 ? 'order:' + vehiclesIndex : 'order:' + (vehiclesIndex*2)" v-for="(vehicles, vehiclesIndex) in main.children[0].children" :key="100 + vehiclesIndex" :class="['MainMenuRangePicker__content', 'MainMenuRangePicker__content_pos0', {'is-lastActive' : isActive(vehiclesIndex)}, {'is-active' : isActive(vehiclesIndex)}]" id="tab-0">
                                                <div class="MainMenuRangeModels">
                                                    <div v-for="(vehicleGroupe, vehicleGroupeIndex) in vehicles.children" :key="vehicleGroupeIndex"  :class="[{'MainMenuModel' : !vehicleGroupe.meta.more_vehicles}, {'MainMenuRangeModels__element' : vehicleGroupe.meta.more_vehicles}]">
                                                        <figure v-if="!vehicleGroupe.meta.more_vehicles" class="MainMenuModel__imageWrapper">
                                                            <picture class="LazyPictureElement LazyPictureElement_loaded WebrenderPictureElement MainMenuModel__image is-ratio-forced">
                                                                <img :src="vehicleGroupe.icon" :alt="vehicleGroupe.title" class="PictureElement__imgDefault">
                                                            </picture>
                                                        </figure>
                                                        <div v-if="!vehicleGroupe.meta.more_vehicles" class="MainMenuModel__nameAndTags">
                                                            <a v-if="!vehicleGroupe.meta.more_vehicles" :title="vehicleGroupe.title" class="MainMenuModel__name" :href="vehicleGroupe.url">{{vehicleGroupe.title}}</a>
                                                            <div v-if="vehicleGroupe.meta.blueSubText" class="EnergyTagList MainMenuModel__tagsList is-inline">
                                                                <ul class="EnergyTagList__tagList">
                                                                    <li class="EnergyTagList__tag has-noBorder">
                                                                        <span class="EnergyTag has-noBorder is-EV">{{vehicleGroupe.meta.blueSubText}}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div v-if="vehicleGroupe.meta.more_vehicles" class="MainMenuRangeModels__elementLinkInner">
                                                            <button class="ButtonMore MainMenuRangeModels__link" @click="openMore(vehicleGroupe.url)">
                                                                <span class="ButtonMore__picto">
                                                                    <span class="u-hidden-wording">more</span>
                                                                </span>
                                                            </button>
                                                            <a title="" class="MainMenuRangeModels__linkLabel" :href="vehicleGroupe.url">{{vehicleGroupe.title}}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul :style="'order:' + (main.children[0].children.length * 2 + 1)" class="MainMenuRangePicker__links">
                                                <li v-for="(vehicleLinks, vehicleLinksIndex) in main.children[1].children" :key="vehicleLinksIndex" class="MainMenuRangePicker__listElement">
                                                    <a :title="vehicleLinks.title" class="MainMenuRangePicker__link MainMenuRangePicker__ctaLink" target="_blank" :href="vehicleLinks.url">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.343 185.343" class="MainMenuRangePicker__svgArrowRight">
                                                            <path d="M51.707 185.343a10.692 10.692 0 01-7.593-3.149 10.724 10.724 0 010-15.175l74.352-74.347L44.114 18.32c-4.194-4.194-4.194-10.987 0-15.175 4.194-4.194 10.987-4.194 15.18 0l81.934 81.934c4.194 4.194 4.194 10.987 0 15.175l-81.934 81.939a10.678 10.678 0 01-7.587 3.15z"></path>
                                                        </svg>{{vehicleLinks.title}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-if="main.meta.dropdown" class="MainMenuEntry__dropdown">
                                        <ul class="MainMenuDropDownList">
                                            <li v-for="(dropdown, dropdownIndex) in main.children" :key="dropdownIndex" class="MainMenuDropDownList__element">
                                                <a :title="dropdown.title" class="MainMenuDropDownList__link" data-track="click" :data-track-button-text="dropdown.title" :data-track-destination="dropdown.url" data-track-location-in-page="secondary-nav" data-track-event="navigationClick" :href="dropdown.url">{{dropdown.title}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-if="!main.meta.vehicle_menu && !main.meta.dropdown && !main.meta.mainMenuLink" class="EditorialContentZone MainMenuEntry__megaDropdown">
                                        <ul class="MainMenuMegaDropDownContent">
                                            <SubDropdown 
                                                v-for="(megaDropdown, megaDropdownIndex) in main.children" 
                                                :key="megaDropdownIndex" 
                                                :megaDropdown="megaDropdown"
                                                :megaDropdownIndex="megaDropdownIndex"
                                            />
                                        </ul>
                                    </div>
                                    <a v-if="main.meta.mainMenuLink" :title="main.title" class="MainMenuEntry__link" data-track="click" :data-track-button-text="main.title" :data-track-destination="main.url" data-track-location-in-page="primary-nav" data-track-event="navigationClick" :href="main.url" >
                                       {{main.title}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                       

                        <div v-for="(button, buttonIndex) in headerButtons" :key="buttonIndex" :class="[{'DaciaHeaderButtons' : !button.meta.searchLink}, {'SearchLink' : button.meta.searchLink}]">
                            <a :title="button.title" :class="[{'DaciaHeaderButtons__button' : !button.meta.searchLink}, {'SearchLink__button' : button.meta.searchLink}]" :href="button.url">
                                <div :class="[{'DaciaHeaderButtons__icon' : !button.meta.searchLink}, {'SearchLink__icon' : button.meta.searchLink}]">
                                    <svg :viewBox="button.meta.dimensions" :class="[{'DaciaHeaderButtons__iconSvg' : !button.meta.searchLink}, {'SearchLink__iconSvg' : button.meta.searchLink}]">
                                        <path :d="button.icon"></path>
                                    </svg>
                                </div>
                                <div :class="[{'DaciaHeaderButtons__text' : !button.meta.searchLink}, {'SearchLink__text' : button.meta.searchLink}]">{{button.title}}</div>
                            </a>
                        </div>
                    </div>
                    <div :class="['header-15xsivr', 'eecaeth0', {'mobile-header' : mobileHeader}]">
                        <a v-for="(topMenuItem, topMenuIndex) in topNavigation" :key="topMenuIndex" :href="topMenuItem.url" class="header-1wjvsqq eecaeth3">{{ topMenuItem.title }}</a>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
    import axios from 'axios';
    import SubDropdown from './components/SubDropdown.vue';

    export default {
        components: { SubDropdown },
        name: 'Header',

        props: {
            url: {
                type: String
            }
        },
        
        created() {
            if ("HEADER_FOOTER_SETTINGS" in window) {
                this.fetchNavigation(window.HEADER_FOOTER_SETTINGS.apiUri);
            } else {
                if (this.url) {
                    this.fetchNavigation(this.url);
                }
            }

            window.addEventListener('click', this.del);

            window.addEventListener('resize', this.handleResize);
        },

        beforeDestroy() {
            window.removeEventListener('click', this.del)
        },

        destroyed(){
            window.removeEventListener('resize', this.handleResize)
        },
        
        data() {
            return {
                windowWidth: window.innerWidth,
                activeDropdown: null,
                isMobileOpened: false,
                topNavMenuMobileActive: false,
                activeVehicleType: 0,
                vehicleTypeActive: 0,
                basicInformation: [],
                mainNavigation: [],
                topNavigation: [],
                headerButtons: [],
                mobileHeader: false
            }
        },

        computed: {
            welcomeText() {
                return this.basicInformation && this.basicInformation.meta && this.basicInformation.meta.welcome_text;
            },
            paleta() {
                return this.basicInformation && this.basicInformation.meta && this.basicInformation.meta.paleta_vozila;
            },
            paleta_url() {
                return this.basicInformation && this.basicInformation.meta && this.basicInformation.meta.paleta_vozila_url;
            },
            izbornik() {
                return this.basicInformation && this.basicInformation.meta && this.basicInformation.meta.izbornik;
            },
            gledate(){
                return this.basicInformation && this.basicInformation.meta && this.basicInformation.meta.gledate;
            }, 
            stranica(){
                return this.basicInformation && this.basicInformation.meta && this.basicInformation.meta.stranica;
            }

        },

        methods: {
            fetchNavigation(apiUri) {
                axios.get(apiUri)
                    .then((response) => {
                        this.basicInformation = response.data;
                        this.mainNavigation = response.data.header.schema;
                        this.topNavigation = response.data.top_nav.schema;
                        this.headerButtons = response.data.header_buttons.schema;
                    })
            },
            toggleDropdown(index){
                if(index === this.activeDropdown) {
                    this.activeDropdown = null
                } else {
                    this.activeDropdown = index
                }
                this.mobileHeader = !this.mobileHeader
            },
            isActiveDropdown(index){
                if(index === this.activeDropdown) {
                    return true;
                } else {
                    false;
                }
            }, 
            toggleVehicleGroup(index){
                if(this.windowWidth >= 1024){
                    if(this.activeVehicleType !== index) {
                        this.activeVehicleType = index
                    }
                } else if(this.windowWidth < 1024){
                    if(this.activeVehicleType === index) {
                        this.activeVehicleType = null
                    } else {
                        this.activeVehicleType = index
                    }
                }
            },
            isActive(index){
                if(this.activeVehicleType === index) {
                    return true
                } else {
                    return false
                }
            },
            openMore(index){
                window.open(index)
            },
            toggleMobile(){
                if(this.isMobileOpened){
                    this.isMobileOpened = false;
                    document.body.classList.remove("modal-is-active");
                } else {
                    this.isMobileOpened = true;
                    document.body.classList.add("modal-is-active");
                }
            },
            mobileTopMenuVisible(index){
                if(index === 0) {
                    this.topNavMenuMobileActive = !this.topNavMenuMobileActive
                }
            },
            del(e) {
                if(! this.$el.contains(e.target)){
                    this.activeDropdown = null
                }
            },
            handleResize(){
                this.windowWidth = window.innerWidth;
                if(this.windowWidth >= 1024){
                    this.topNavMenuMobileActive = false
                }
            }
        }
    }
</script>

<style scoped>
    @import './assets/css/dacia_header.css';

    @font-face{
        font-family: "readBeta2 sans-serif";
        font-weight:400;
        font-display:swap;
        src:url(./assets/fonts/readBeta2-light-v3100-Latin.woff2) format("woff2")
    }
    @font-face{
        font-family: "readBeta2 sans-serif";
        font-weight:700;
        font-display:swap;
        src:url(./assets/fonts/readBeta2-medium-v3100-Latin.woff2) format("woff2")
    }
    @font-face{
        font-family: "daciaSpirit, sans-serif";
        font-weight:700;
        font-display:swap;
        src:url(./assets/fonts/daciaSpirit-bold-v1000-Latin.woff2) format("woff")
    }
    .header-ngvjus {
        display: none;
    }
    .header-15xsivr {
        display: flex;
        flex-direction: column;
        padding: 16px;
        margin-top: 40px;
    }
    .header-1wjvsqq {
        font-size: 14px;
        line-height: 17px;
        padding-right: 16px;
        padding-bottom: 24px;
        color: rgb(0, 0, 0);
        font-weight: 300;
        text-transform: uppercase;
        font-family: Read-Regular, sans-serif;
        text-decoration: none;
    }
    @media screen and (min-width: 1024px) {
        .header-ngvjus {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid rgb(242, 242, 242);
            padding: 8px 0px;
            flex-direction: row;
            background-color: rgb(242, 242, 242);
            padding: 8px 16px 8px 24px;
        }
        .header-db6xb7 {
            width: auto;
            margin-right: 16px;
            display: flex;
            flex-direction: row;
        }
        .header-1wjvsqq {
            font-size: 11px;
            line-height: 17px;
            padding-right: 16px;
            padding-bottom: 0px;
            font-weight: 700;
            text-transform: uppercase;
            font-family: Read-Regular, sans-serif;
            text-decoration: none;
            color: #000;
        }
        .header-15xsivr {
            display: none;
        }
    }
    .DaciaMainHeader__bottom.mobile-header {
        flex: 1;
    }
    .header-15xsivr.mobile-header {
        display: none;
    }

</style>
